<template>
    <section v-if="!loading">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            id="course-manager"
            show-arrows
            class="mt-2"
        >
            <v-tab
                v-for="item in filteredItems"
                :key="item"
                class="text-capitalize poppins f14 fw600 secondary--text"
                active-class="secondary-1--text"
            >
                <span>{{ item }}</span>
            </v-tab>
        </v-tabs>
        <v-tabs-items
            v-model="tab"
            style="background-color: transparent"
            id="main-container"
        >
            <v-tab-item v-for="item in filteredItems" :key="item">
                <v-row v-if="item === 'Course Info'">
                    <v-col
                        :cols="
                            $vuetify.breakpoint.mobile
                                ? 12
                                : activity_log.show
                                  ? 8
                                  : 12
                        "
                    >
                        <AboutCourse
                            :course="course"
                            :activity_log="activity_log.show"
                            :classes="classes"
                            :permission="coursePermission"
                        />
                    </v-col>
                    <v-col
                        :cols="
                            $vuetify.breakpoint.mobile
                                ? 12
                                : activity_log
                                  ? 4
                                  : 12
                        "
                    >
                        <CourseActivityLog
                            :activity_log="activity_log.show"
                            @close="closeActivityLog"
                            :popup="activity_log.popup"
                        />
                    </v-col>
                </v-row>
                <ModuleEditor
                    v-if="item === 'Module Editor'"
                    :course="course"
                    :topicLoading="topicLoading"
                    :subtopicLoading="subtopicLoading"
                    :permission="coursePermission"
                    @addAssessment="addAssessment"
                    @viewAssessment="viewAssessment"
                    @viewModuleEvaluation="viewModuleEvaluation"
                    @viewCourseEvaluation="viewCourseEvaluation"
                />
                <Synchronous
                    v-if="item === 'Synchronous'"
                    :course_id="course.id"
                    :status="course.status"
                    :permission="coursePermission"
                />
                <Assessment
                    v-if="item === 'Assessment'"
                    :course_id="course.id"
                    :item="selectedAssessment"
                    :status="course.status"
                    :assessment_questions="
                        assessment_questions.filter(
                            (a) => a.id === selectedAssessment.id,
                        )[0]
                    "
                    :permission="coursePermission"
                    @closeAssessment="closeAssessment"
                />
                <ModuleEvaluation
                    v-if="item === 'Module Evaluation'"
                    :evaluation="selectedModuleEvaluation"
                    :status="course.status"
                    :permission="coursePermission"
                    :loading="loadingViewEvaluation"
                />
                <CourseEvaluation
                    v-if="item === 'Course Evaluation'"
                    :evaluation="selectedCourseEvaluation"
                    :status="course.status"
                    :permission="coursePermission"
                    :loading="loadingViewEvaluation"
                />
                <Attendee
                    v-if="item === 'Attendees'"
                    :enrollments="course.enrollments"
                    @load-attendees="enrolleePaginate($event)"
                    @search-enrollee="searchEnrollee($event)"
                />
            </v-tab-item>
        </v-tabs-items>

        <div
            class="my-10 secondary--text poppins f12 font-italic text-capitalize"
        >
            Saved {{ getUpdatedAt }}
        </div>

        <v-dialog max-width="350" v-model="dialog" :persistent="submitting">
            <v-sheet class="border" v-if="!saved">
                <div v-if="!submitting">
                    <div class="text-center pa-5">
                        <v-icon color="success" size="46"
                            >mdi-check-circle-outline</v-icon
                        >
                        <h4 class="poppins primary--text fw600 my-3">
                            Your changes will be saved to drafts.
                        </h4>
                    </div>
                    <v-divider />
                    <div class="text-center py-2">
                        <v-btn
                            text
                            class="f12 poppins text-capitalize mx-1"
                            width="100"
                            @click="dialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="primary"
                            class="f12 poppins text-capitalize mx-1"
                            width="100"
                            @click="save"
                            >Confirm</v-btn
                        >
                    </div>
                </div>
                <div class="text-center pa-5" v-else>
                    <v-progress-circular
                        size="32"
                        indeterminate
                        color="green"
                    />
                    <h4 class="poppins primary--text fw600 my-3">Saving...</h4>
                </div>
            </v-sheet>
            <v-sheet class="border" v-else>
                <div class="text-center pa-5">
                    <v-icon color="success" size="46">mdi-check-circle</v-icon>
                    <h4 class="poppins primary--text fw600 my-3">
                        Saved to drafts.
                    </h4>
                </div>
            </v-sheet>
        </v-dialog>
    </section>
    <circular v-else />
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import items from "../../constants/tabs/course/coursemanager";
import AboutCourse from "../../components/teacher/course/AboutCourse.vue";
import Attendee from "../../components/teacher/attendee/ViewAttendee.vue";
import ModuleEditor from "../../components/teacher/course/ModuleEditor.vue";
import Assessment from "../../components/teacher/assessment/Assessment.vue";
import Synchronous from "../../components/teacher/course/Synchronous.vue";
import CourseActivityLog from "../../components/teacher/course/CourseActivityLog.vue";
import CourseEvaluation from "../../components/teacher/course/CourseEvaluation.vue";
import ModuleEvaluation from "../../components/teacher/course/ModuleEvaluation.vue";

export default {
    components: {
        ModuleEditor,
        AboutCourse,
        Assessment,
        Synchronous,
        CourseActivityLog,
        ModuleEvaluation,
        CourseEvaluation,
        Attendee,
    },
    data: () => ({
        items: items,
        loading: true,
        dialog: false,
        saved: true,
        next: null,
        submitting: false,
        selectedAssessment: null,
        selectedModuleEvaluation: null,
        selectedCourseEvaluation: null,
        topicLoading: false,
        subtopicLoading: false,
        loadingSave: false,
        loadingViewEvaluation: false,
        enrolleePage: 1,
        enrollee: "",
    }),
    mounted() {
        if (this.$route.query.page) {
            this.enrolleePage = this.$route.query.page;
        }
        this.initialize();
    },
    computed: {
        ...mapState({
            tenant: (state) => state.tenant,
        }),
        ...mapState("instructor", {
            course: (state) => state.course,
            classes: (state) => state.classes,
            assessments: (state) => state.assessments,
            assessment_classes: (state) => state.assessment_classes,
            assessment_questions: (state) => state.assessment_questions,
            activity_log: (state) => state.activity_log,
        }),
        getUpdatedAt() {
            return this.$dateFormat.calendar(this.course.updated_at);
        },
        tab: {
            get() {
                return parseInt(this.$route.query.tab);
            },
            set(tab) {
                this.$router.replace({
                    query: { tab: tab, timestamp: Date.now() },
                });
            },
        },
        filteredItems() {
            return this.items.filter((item) => {
                if (
                    item === "Synchronous" &&
                    this.course.learning_mode !== "BLENDED"
                ) {
                    return false;
                }
                if (
                    item === "Attendees" &&
                    this.course.learning_mode !== "BLENDED"
                ) {
                    return false;
                }
                return true;
            });
        },
        coursePermission() {
            return this.course.my_permission.length > 0
                ? this.course.my_permission[0].pivot.permission
                : "owner";
        },
    },
    watch: {
        assessments(val) {
            if (this.selectedAssessment) {
                this.selectedAssessment = {
                    ...this.selectedAssessment,
                    ...this.assessments.filter(
                        (i) => i.id == this.selectedAssessment.id,
                    )[0],
                };
            }
        },
    },

    methods: {
        ...mapActions("instructor", [
            "showCourseAction",
            "getClassesAction",
            "getAssessmentsAction",
            "addAssessmentAction",
            "updateAssessmentAction",
            "showTaggedUsersAction",
            "getTagClassAssessmentAction",
        ]),
        ...mapMutations(["alertMutation"]),
        ...mapMutations("instructor", [
            "activityLogMutation",
            "evaluationQuestionMutation",
        ]),
        async enrolleePaginate(page) {
            this.enrolleePage = page;
            this.$router.replace({
                query: { tab: this.tab, timestamp: Date.now(), page: page },
            });
            await this.initialize();
        },
        async searchEnrollee(e) {
            this.enrollee = e;
            this.enrolleePage = 1;
            this.$router.replace({
                query: {
                    tab: this.tab,
                    timestamp: Date.now(),
                    page: this.enrolleePage,
                    query: e,
                },
            });
            await this.initialize();
        },
        async initialize() {
            await this.showCourseAction({
                id: this.$route.params.id,
                page: this.enrolleePage,
                query: this.enrollee,
            }).then(() => {
                this.loading = false;
                this.getAssessmentsAction({ course_id: this.course.id });
            });
            this.getClassesAction();
            if (this.filteredItems.includes("Assessment")) {
                this.filteredItems.pop();
            }
        },

        closeActivityLog() {
            this.activityLogMutation(false);
        },

        save() {
            this.submitting = true;
            setTimeout(() => {
                this.saved = true;
                setTimeout(() => {
                    this.next();
                }, 2000);
            }, 2000);
        },

        addAssessment(data) {
            if (data.is_topic) {
                this.topicLoading = true;
            } else {
                this.subtopicLoading = true;
            }

            this.addAssessmentAction({
                course_id: this.course.id,
                module_id: data.module_id,
                is_topic: data.is_topic,
                topic_or_sub_topic_id: data.topic_or_subtopic_id,
                title: data.title,
                type_of_assessment: "graded_assessment",
                require_checking: false,
            }).then(() => {
                this.topicLoading = false;
                this.subtopicLoading = false;
                this.alertMutation({
                    show: true,
                    text: "Assessment Successfully Created!",
                    type: "success",
                });
            });
        },

        saveAssessment() {
            this.loadingSave = true;
            if (this.selectedAssessment.max_attempt == null) {
                this.selectedAssessment.max_attempt = 1;
            }
            this.updateAssessmentAction(this.selectedAssessment).then(() => {
                this.loadingSave = false;
                this.alertMutation({
                    show: true,
                    text: "Assessment Successfully Saved!",
                    type: "success",
                });
            });
        },

        closeAssessment() {
            this.filteredItems.pop();
            this.selectedAssessment = null;
            this.tab = this.filteredItems.findIndex(
                (i) => i === "Module Editor",
            );
            this.alertMutation({
                show: true,
                text: "Assessment Successfully Deleted!",
                type: "success",
            });
        },

        viewAssessment(data) {
            // Check if the 'Assessment' tab already exists in the filteredItems array
            const assessmentTabIndex = this.filteredItems.findIndex(
                (i) => i === "Assessment",
            );

            // If the 'Assessment' tab does not exist and the filteredItems length is 4, add it
            if (assessmentTabIndex === -1 && this.filteredItems.length === 4) {
                this.filteredItems.push("Assessment");
            } else if (assessmentTabIndex === -1) {
                // If the 'Assessment' tab does not exist but the length is not 4, ensure only 3 items
                this.filteredItems.splice(3, 1, "Assessment");
                this.items.push("Assessment");
            } // No else required since if 'Assessment' exists, we don't need to add it again

            // if (this.filteredItems.length === 4) {
            //   this.items.push('Assessment');
            // } else {
            //   this.filteredItems.splice(3, 0);
            //   this.items.push('Assessment');
            // }

            this.selectedAssessment = {
                ...this.assessments.filter((i) => i.id == data.id)[0],
                ...data,
            };
            if (this.selectedAssessment.type_of_assessment === null) {
                this.selectedAssessment.type_of_assessment =
                    "graded_assessment";
            }
            this.tab = this.filteredItems.findIndex((i) => i === "Assessment");
            this.$vuetify.goTo("#course-manager", { offset: 0 });

            this.showTaggedUsersAction({
                course_id: this.selectedAssessment.course_id,
                id: this.selectedAssessment.id,
                state_classes: this.assessment_classes,
            }).then(() => {
                this.getTagClassAssessmentAction({
                    id: this.selectedAssessment.id,
                });
            });
        },

        viewModuleEvaluation(evaluation) {
            // Check if 'Module Evaluation' Tab Exists
            const moduleEvaluationTabIndex = this.filteredItems.findIndex(
                (i) => i === "Module Evaluation",
            );

            // Add or Replace 'Module Evaluation' Tab
            if (
                moduleEvaluationTabIndex === -1 &&
                this.filteredItems.length === 4
            ) {
                this.filteredItems.push("Module Evaluation");
            } else if (moduleEvaluationTabIndex === -1) {
                this.filteredItems.splice(3, 1, "Module Evaluation");
                this.items.push("Module Evaluation");
            } // No else required since if 'Module Evaluation' exists, we don't need to add it again

            // Set the tab index to the 'Module Evaluation' tab
            this.tab = this.filteredItems.findIndex(
                (i) => i === "Module Evaluation",
            );

            // Scroll to the course manager section
            this.$vuetify.goTo("#course-manager", { offset: 0 });

            this.loadingViewEvaluation = true;

            // Fetch and set the selected module evaluation data
            this.$api
                .get(`instructor/module/evaluations/${evaluation.id}`)
                .then((res) => {
                    this.selectedModuleEvaluation = res.data.data;
                    this.evaluationQuestionMutation(
                        res.data.data.module_evaluation_questions,
                    );
                    this.loadingViewEvaluation = false;
                });
        },

        viewCourseEvaluation(evaluation) {
            // Check if 'Course Evaluation' Tab Exists
            const courseEvaluationTabIndex = this.filteredItems.findIndex(
                (i) => i === "Course Evaluation",
            );

            // Add or Replace 'Course Evaluation' Tab
            if (
                courseEvaluationTabIndex === -1 &&
                this.filteredItems.length === 4
            ) {
                this.filteredItems.push("Course Evaluation");
            } else if (courseEvaluationTabIndex === -1) {
                this.filteredItems.splice(3, 1, "Course Evaluation");
                this.items.push("Course Evaluation");
            } // No else required since if 'Course Evaluation' exists, we don't need to add it again

            // Set the tab index to the 'Course Evaluation' tab
            this.tab = this.filteredItems.findIndex(
                (i) => i === "Course Evaluation",
            );

            // Scroll to the course manager section
            this.$vuetify.goTo("#course-manager", { offset: 0 });

            this.loadingViewEvaluation = true;

            // Fetch and set the selected course evaluation data
            this.$api
                .get(`instructor/course/evaluations/${evaluation.id}`)
                .then((res) => {
                    this.selectedCourseEvaluation = res.data.data;
                    this.evaluationQuestionMutation(
                        res.data.data.course_evaluation_questions,
                    );
                    this.loadingViewEvaluation = false;
                });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.saved) return next();
        this.dialog = true;
        this.next = next;
    },
};
</script>
