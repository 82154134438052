<template>
    <div>
        <section class="d-flex justify-end align-center ml-auto flex-wrap my-5">
            <v-text-field
                outlined
                dense
                flat
                style="max-width: 400px"
                class="general-custom-field f12"
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                @input="searchInput"
                v-model="search"
            />
            <v-spacer></v-spacer>
            <v-btn
                @click="markAsPresent"
                outlined
                :loading="loading1"
                dense
                color="primary"
                class="poppins f12 mr-2"
            >
                Mark as Present
            </v-btn>

            <v-btn
                @click="markAsAbsent"
                outlined
                :loading="loading2"
                dense
                color="primary"
                class="poppins f12 mr-2"
            >
                Mark as Absent
            </v-btn>
            <v-btn
                dense
                color="primary"
                class="poppins f12 mr-2"
                @click="exportData"
            >
                <v-icon left> mdi-export-variant </v-icon>
                Export
            </v-btn>
        </section>
        <v-data-table
            :headers="headers"
            :items="enrollments.data"
            v-model="selected"
            hide-default-footer
            show-select
            class="text--center poppins custom-border"
        >
            <template v-slot:item.status="{ item }">
                <div>
                    {{
                        item.user.attendance
                            ? item.user.attendance.attendance
                                ? "Present"
                                : "Absent"
                            : "Pending"
                    }}
                </div>
            </template>
        </v-data-table>
        <v-pagination
            v-model="current_page"
            :length="enrollments.last_page"
            :total-visible="7"
        ></v-pagination>
    </div>
</template>
<script>
import { attendees_tbl } from "../../../constants/tblheaders/attendee";
import { mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";
export default {
    props: ["enrollments"],
    data() {
        return {
            headers: [
                {
                    text: "Status",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Email",
                    sortable: false,
                    value: "user.email",
                },
                { text: "Name", sortable: false, value: "user.first_name" },
                { text: "Year", sortable: false, value: "user.year_level" },
                { text: "Chapter", sortable: false, value: "user.chapter" },
                {
                    text: "Institution",
                    sortable: false,
                    value: "user.institution",
                },
            ],
            //attendes_tbl,

            loading: false,
            loading1: false,
            loading2: false,
            attendees: [],
            selected: [],
            selectAll: false,
            search: "",
            pageCount: 1,
            page: 1,
            paginate: "10",
            paginatedStudents: [],
        };
    },

    watch: {
        selectAll(val) {
            if (val) {
                let _selected = [];

                this.attendees.forEach((item) => {
                    if (!this.selected.includes(item)) {
                        _selected.push(item.id);
                    }
                });

                this.selected = [..._selected];
            } else if (!val && this.attendees.length === this.selected.length) {
                this.selected = [];
            }
        },
        selected(val) {
            if (val.length !== this.attendees.length) {
                this.selectAll = false;
            } else if (
                val.length === this.attendees.length &&
                this.attendees.length !== 0
            ) {
                this.selectAll = true;
            }
        },
    },

    mounted() {
        /****
        this.getAttendee();
        this.getStudentList();
        *** */
    },

    methods: {
        ...mapActions("instructor", [
            "updateAttendanceStatusAction",
            "getEnrolleeStudentList",
            "exportingData"
        ]),

        ...mapMutations(["alertMutation"]),

        searchInput: _.debounce(function (e) {
            this.$emit("search-enrollee", e);
        }, 500),

        getAttendee() {
            const attendees = [];
            //this.course.forEach((item) => {
            //    attendees.push(item.user);
            //});
            return attendees;
        },

        getStudentList() {
            /*****
            this.attendees = this.getAttendee();
            let filteredAttendees = this.attendees;
            if (this.search) {
                const searchLower = this.search.toLowerCase();
                filteredAttendees = this.attendees.filter((student) =>
                    student.name.toLowerCase().includes(searchLower),
                );
            }
            this.pageCount = Math.ceil(
                filteredAttendees.length / this.paginate,
            );
            this.paginatedStudents = filteredAttendees.slice(
                (this.page - 1) * this.paginate,
                this.page * this.paginate,
            );
            ****/
        },
        // getStudentList() {
        //     this.attendees = this.getAttendee();
        //     this.pageCount = Math.ceil(this.attendees.length / this.paginate);
        //     this.paginatedStudents = this.attendees.slice((this.page - 1) * this.paginate, this.page * this.paginate);
        // }
        markAsPresent() {
            this.loading1 = true;
            this.updateAttendanceStatusAction({
                course_id: this.$route.params.id,
                users: this.selected,
                attendance: true,
            })
                .then(() => {
                    this.alertMutation({
                        show: true,
                        text: `Successfully updated attendance`,
                        type: "success",
                    });
                    this.getStudentList();
                })
                .catch(() => {
                    this.alertMutation({
                        show: true,
                        text: `Something went wrong.`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading1 = false;
                    this.selected = [];
                    this.$emit("load-attendees", this.current_page);
                });
        },
        markAsAbsent() {
            this.loading2 = true;
            this.updateAttendanceStatusAction({
                course_id: this.$route.params.id,
                users: this.selected,
                attendance: false,
            })
                .then(() => {
                    this.alertMutation({
                        show: true,
                        text: `Successfully updated attendance`,
                        type: "success",
                    });
                    this.getStudentList();
                })
                .catch(() => {
                    this.alertMutation({
                        show: true,
                        text: `Something went wrong.`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading2 = false;
                    this.selected = [];
                    this.$emit("load-attendees", this.current_page);
                });
        },
        getAttendanceStatus(student) {
            // Filter attendance records based on course_id
            let attendance = student.attendance.filter(
                (record) =>
                    record.course_id === parseInt(this.$route.params.id),
            );
            console.log("Filtered Attendance Data:", attendance);

            // Check if any attendance records were found
            if (attendance.length === 0) {
                return "No Record";
            } else {
                // Retrieve attendance status from the first matching record
                let attendanceRecord = attendance[0].attendance;
                return attendanceRecord === 1 ? "Present" : "Absent";
            }
        },
        exportData() {
            this.exportingData({
                course_id: this.$route.params.id,
                selected_users: this.selected
            })
            .then((response) => {
                // Directly handle the success message since no download URL is expected
                this.alertMutation({
                    show: true,
                    text: `Export started successfully`,
                    type: "success",
                });

                // The browser should automatically prompt for the CSV download.
                // No need to handle a download_url.
            })
            .catch((error) => {
                this.alertMutation({
                    show: true,
                    text: `Failed to export data: ${error.message}`,
                    type: "error",
                });
            });
        }

    },

    computed: {
        current_page: {
            get() {
                return this.enrollments.current_page;
            },
            set(val) {
                console.log(val);
                this.enrollments.current_page = val;
                this.$emit("load-attendees", val);
            },
        },
        /***
        ...mapState("instructor", {
            enrolled_student: (state) => state.enrolled_student,
        }),
         */
    },
};
</script>
<style></style>
